<template>
  <div class="subscription-summary px-4">
    <span
      class="thank-you-msg"
      v-html="$sanitizeHtml($t('thankYouMsg'))"/>
    <v-card class="mt-8">
      <v-card-text>
        <h3 v-text="$t('yourSubscription')"/>
        <h4 v-text="$t('plan')"/>
        <CyTag variant="secondary">
          {{ awsMarketplaceSubscription.planCanonical }}
        </CyTag>
        <h4 v-text="$t('users')"/>
        <span v-text="awsMarketplaceSubscription.membersCount"/>
        <h4 v-text="$t('expiration')"/>
        <span v-text="$date.format($date.parseISO(awsMarketplaceSubscription.expiresAt), 'MMM dd, yyyy')"/>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CyAwsMarketplaceSubscriptionSummary',
  computed: {
    ...mapState('auth', {
      awsMarketplaceSubscription: (state) => state.awsMarketplaceSubscription,
    }),
  },
  i18n: {
    messages: {
      en: {
        thankYouMsg: 'Thank you for subscribing to Cycloid. <b>Your hybrid cloud journey starts now.</b>',
        yourSubscription: 'Your subscription:',
        plan: '@:untranslated.plan',
        users: 'Users',
        expiration: 'Expiration',
      },
      es: {
        thankYouMsg: 'Gracias por suscribirte a Cycloid. <b>Su viaje a la nube híbrida comienza ahora.</b>',
        yourSubscription: 'Su suscripción:',
        plan: '@:untranslated.plan',
        users: 'Usarios',
        expiration: 'Caducidad',
      },
      fr: {
        thankYouMsg: 'Merci de vous être abonné à Cycloid. <b>Votre voyage vers le cloud hybride commence maintenant.</b>',
        yourSubscription: 'Votre abonnement:',
        plan: '@:untranslated.plan',
        users: 'Utilisateurs',
        expiration: 'Expiration',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.subscription-summary {
  z-index: 1;
  flex-grow: 1;
  align-self: center;
  max-width: 376px;

  .thank-you-msg {
    font-size: $font-size-xl;
  }

  h4 {
    margin-top: 16px;
  }
}

@media screen and (width >= 745px) {
  .subscription-summary {
    position: absolute;
    top: 160px;

    .thank-you-msg {
      font-size: $font-size-h2;
      font-weight: $font-weight-default;
    }
  }
}
</style>
