<template>
  <div
    :class="['locale-select pr-4 pl-4', {
      'locale-select--white-page': _.get($route.meta, 'layout.isWhite'),
    }]">
    <v-select
      :value="$i18n.locale"
      :items="localesList"
      :label="$t('forms.fieldLanguage')"
      append-icon="expand_more"
      background-color="transparent"
      class="locale-select__select"
      dense
      data-cy="locale-switcher"
      flat
      hide-selected
      item-text="name"
      item-value="locale"
      prepend-icon="mdi-web"
      required
      solo
      @change="(locale) => CHANGE_LOCALE(locale)"/>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CyLocaleSelect',
  computed: {
    ...mapGetters('i18n', [
      'localesList',
    ]),
  },
  methods: {
    ...mapMutations('i18n', [
      'CHANGE_LOCALE',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.locale-select {
  position: absolute;
  top: 24px;
  right: 32px;

  &__select {
    width: 150px;
    height: 40px;
    font-size: $font-size-default;

    ::v-deep {
      .v-icon.v-icon {
        color: cy-get-color("primary") !important;
      }

      .v-input__icon--prepend {
        position: relative;
        right: -10px;
      }

      .v-select__selection {
        margin-right: 0;
        color: cy-get-color("primary");
        font-weight: $font-weight-bolder;
      }
    }
  }
}

@media screen and (width <= 745px) {
  .locale-select {
    top: 275px;

    &:not(.locale-select--white-page) {
      left: 50%;
      width: 380px;
      transform: translateX(-50%);
    }

    &__select {
      margin-left: auto;
      font-size: $font-size-sm;

      ::v-deep {
        .v-icon.v-icon {
          width: 15px;
          height: 15px;
        }

        .v-input__prepend-outer {
          position: relative;
          right: -20px;
        }

        .v-select__selections {
          position: relative;
          right: -15px;
        }
      }
    }
  }
}
</style>
