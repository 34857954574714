<template>
  <div
    :class="['public-container', {
      'public-container--white': hasWhiteLayout,
      'top-pane-overlap': topPaneOverlap,
    }]"
    data-cy="public-container">
    <CyAlertModal/>

    <template v-if="hasTwoPaneLayout">
      <div
        :class="['left-pane', {
          'left-pane--mobile': isTabletOrMobile,
        }]">
        <div class="left-pane__content">
          <CyCompanyLogo data-cy="company-logo"/>

          <div
            v-if="!$showDevThings"
            class="pa-3">
            <CyDevShowDevThingsBtn/>
          </div>
          <CyDevLayerActivatorBtn
            v-else
            class="pa-3"
            v-bind="{ isSidebarCollapsed: false }"/>

          <CyAwsMarketplaceSubscriptionSummary v-if="awsMarketplaceSubscription"/>
        </div>

        <svg
          data-svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          width="100%"
          height="100%"
          viewBox="0 0 500 500"
          data-cy="svg"/>
      </div>

      <div class="right-pane d-flex">
        <slot/>
      </div>
    </template>

    <template v-else>
      <CyDevShowDevThingsBtn v-if="!$showDevThings"/>
      <CyDevLayerActivatorBtn
        v-else
        v-bind="{ isSidebarCollapsed: false }"/>
      <slot/>
    </template>

    <CyDevLayer/>

    <CyLocaleSelect v-if="$route.name !== 'marketplaceNewOrganization'"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CyAlertModal from '@/components/alert-modal.vue'
import CyAwsMarketplaceSubscriptionSummary from '@/components/aws-marketplace-subscription-summary.vue'
import CyCompanyLogo from '@/components/company-logo.vue'
import CyDevLayerActivatorBtn from '@/components/dev/layer-activator-btn.vue'
import CyDevLayer from '@/components/dev/layer.vue'
import CyDevShowDevThingsBtn from '@/components/dev/show-dev-things-btn.vue'
import CyLocaleSelect from '@/components/locale-select.vue'

export default {
  name: 'CyPublicLayout',
  components: {
    CyAlertModal,
    CyCompanyLogo,
    CyDevLayer,
    CyDevLayerActivatorBtn,
    CyDevShowDevThingsBtn,
    CyLocaleSelect,
    CyAwsMarketplaceSubscriptionSummary,
  },
  computed: {
    ...mapState('auth', {
      awsMarketplaceSubscription: (state) => state.awsMarketplaceToken,
    }),
    hasWhiteLayout () {
      return this.$route.meta?.layout?.isWhite
    },
    hasTwoPaneLayout () {
      return this.$route.meta?.layout?.isTwoPane
    },
    isTabletOrMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    topPaneOverlap () {
      const isMobile = this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
      const isOverlapping = !!this.awsMarketplaceSubscription
      const isAuthRoute = ['login', 'signUp', 'marketplaceNewOrganization', 'accountVerification'].includes(this.$route.name)
      return isMobile && isOverlapping && isAuthRoute
    },
  },
  watch: {
    $route: {
      handler ({ meta }) {
        if (!meta?.layout?.isTwoPane) return

        this.clearCircles()
        this.drawCircles()
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    clearCircles () {
      const svg = document.querySelector('[data-svg]')
      while (svg?.firstChild) svg.firstChild.remove()
    },
    async drawCircles () {
      const adjustment = this.isTabletOrMobile ? 350 : 0
      const svg = document.querySelector('[data-svg]')
      const { height, width } = svg?.getBoundingClientRect() || {}

      if (!height || !width) {
        await _.$pause(800)
        return this.drawCircles()
      }

      _.times(adjustment ? 20 : 15, () => {
        const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle')
        circle.setAttribute('r', _.random(200, 400))
        circle.setAttribute('cx', width + (adjustment / 2) - _.random(0, 300 + (adjustment / 2)))
        circle.setAttribute('cy', height + adjustment - _.random(0, height / 2))
        circle.setAttribute('stroke-width', _.random(0.4, 1.9))
        circle.setAttribute('data-cy', 'circle')
        svg.appendChild(circle)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.public-container {
  display: flex;
  width: 100%;
  background-color: transparent;

  &--white {
    background-color: cy-get-color("white");

    .show-dev-things-btn {
      position: absolute;
      top: 28px;
      left: 40px;
      width: auto;
      color: cy-get-color("primary") !important;
    }
  }

  .left-pane {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    width: 33%;
    min-width: 224px;
    max-width: 512px;
    background-color: var(--appearance-bg-color);
    color: var(--appearance-text-color);

    &__content {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      height: 100%;
    }

    > ::v-deep svg {
      position: absolute;
      inset: 0;
      background: linear-gradient(155.42deg, rgb(var(--appearance-text-color) / 0), rgb(var(--appearance-text-color) / 0.1));

      @keyframes line-in {
        0% { stroke-dashoffset: 100%; }
        100% { stroke-dashoffset: 0; }
      }

      circle {
        backface-visibility: hidden;
        perspective: 1000px;
        animation: line-in 2000ms;
        stroke-dasharray: 100%;
        stroke: var(--appearance-text-color);
        fill: transparent;
        will-change: stroke-dashoffset;
      }
    }
  }

  .right-pane {
    align-items: center;
    width: 100%;
    background-color: cy-get-color("white");
  }

  &.top-pane-overlap {
    .right-pane {
      padding-top: 140px;
    }

    .locale-select {
      padding-top: 161px;
    }
  }
}

::v-deep .public-page-form {
  &__title {
    padding-left: 4px;
    font-size: $font-size-h3;
    font-weight: $font-weight-bolder;
    line-height: 120%;
  }

  &__logo {
    width: 90%;
    min-width: 150px;
    max-width: 300px;
  }

  &__subtitle {
    font-size: 16px;
    font-weight: bold;
    line-height: 120%;
  }

  &__step {
    display: block;
    margin-bottom: 8px;
    color: cy-get-color("grey", "dark-2");
    font-size: $font-size-sm;
    font-weight: $font-weight-bolder;
    letter-spacing: 0.05em;
    line-height: $line-height-heading;
    text-transform: uppercase;
  }

  .v-card {
    width: 100%;
    max-width: 400px;
    border-radius: 5px;

    &__text {
      padding-top: 40px;
    }

    &--flat {
      background: none;
    }
  }

  .col {
    padding-right: 0;
    padding-left: 0;
  }

  .login-with,
  .signup-with {
    margin: 0 8px;
    color: cy-get-color("grey", "dark-2");
  }

  .cy-btn {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .cy-alert {
    margin-top: -12px;
    margin-bottom: 13px;
  }

  .v-input,
  .cy-btn,
  .password-strength {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
  }

  .v-input {
    margin-top: 0;
    padding-top: 2px;
  }
}

@media screen and (width <= 745px) {
  .public-container {
    display: flex;
    flex-direction: column;

    ::v-deep {
      .markdown__file {
        padding-right: 0;
        padding-left: 0;
      }
    }

    &--white {
      .locale-select {
        top: 25px;
      }
    }

    .left-pane {
      width: 100%;
      max-width: 100%;
      min-height: 256px;
      max-height: 256px;

      &__content {
        flex-direction: row;
        align-items: flex-start;
        width: 400px;
        margin-right: auto;
        margin-left: auto;
        padding-right: 16px;
        padding-left: 16px;
      }

      &--mobile .left-pane__content {
        align-items: center;
        height: auto;
      }
    }

    .right-pane {
      align-items: flex-start;
      height: 100%;
    }
  }

  ::v-deep .public-page-form {
    &__title {
      margin-left: 32px;
      text-align: left;
    }

    .v-card__text {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
</style>
